import { Suspense, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './main.scss'
import whoAmIActions from '../../actions/whoAmIActions'
import Loading from '../../components/Loading/Loading'
import LoadingSpinner from '../../components/Loading/LoadingSpinner'
import { storageKeys } from '../../constants'
import { screenLargerThan } from '../../helpers/helpers'
import useFetch from '../../hooks/useFetch'
import { getSessionValueOrDefault, useKeepInSessionStorage } from '../../hooks/useSessionStorage'
import useToggle from '../../hooks/useToggle'
import { EveliaRootState } from '../../reducerTypes'
import Header from '../Header/Header'
import Sidebar from '../Sidebar'
import AuthorizationWrapper from './AuthorizationWrapper'
import AuthorizeApp from './AuthorizeApp'
import MainRoutes from './MainRoutes'
import MFAModals from './MFAModals'
import SystemCustomerEnvironmentLinkModal from './SystemCustomerEnvironmentLinkModal'

const screenSizeMd = 768

const useSideDrawerAccess = () => {
  const isLargeScreen = screenLargerThan(screenSizeMd)
  const isOpenDefault = getSessionValueOrDefault(storageKeys.SIDE_DRAWER_ACCESS, isLargeScreen)

  const [isOpen, { toggle, disable }] = useToggle(isOpenDefault)
  useKeepInSessionStorage(storageKeys.SIDE_DRAWER_ACCESS, isOpen)

  const onNavigated = useCallback(() => {
    if(!screenLargerThan(screenSizeMd)) {
      disable()
    }
  }, [disable])

  return [
    { className: isOpen ? 'open' : 'closed' },
    toggle,
    onNavigated,
    isOpen ? faAngleLeft : faAngleRight
  ] as const
}

const Main = () => {
  useFetch(whoAmIActions.fetchRequest, { force: true })
  const [containerProps, toggle, onNavigated, icon] = useSideDrawerAccess()
  const { data, busy } = useSelector((state: EveliaRootState) => state.whoAmI)
  const isActive = !!data?.isActive

  return (
    <Loading loading={busy} testId='mainLoader'>
      <AuthorizationWrapper>
        <Header isActive={isActive} />
        <div id='app-main' {...containerProps}>
          <aside id='app-side-drawer'>
            <button className='btn-sidebar-toggle' onClick={toggle}>
              <FontAwesomeIcon className='text-muted' icon={icon} />
            </button>
            <div id='app-side-drawer-content'>
              <Sidebar isActive={isActive} onNavClick={onNavigated} />
            </div>
          </aside>
          <Container tag='main' fluid id='app-content'>
            <Suspense fallback={<LoadingSpinner testId='lazyLoader' />}>
              <AuthorizeApp>
                <MainRoutes />
              </AuthorizeApp>
            </Suspense>
          </Container>
        </div>
      </AuthorizationWrapper>
      <MFAModals />
      <SystemCustomerEnvironmentLinkModal />
    </Loading>
  )
}

export default Main
