import { Badge } from 'reactstrap'
import { appendEitherOrEmpty } from '@evelia/common/helpers'
import { PurchaseOrderModel } from '@evelia/common/types'

import { contentRow } from './PostalDetails'

interface PurchaseOrderDetailsProps {
  item: PurchaseOrderModel
}

const PurchaseOrderDetails = ({ item }: PurchaseOrderDetailsProps) => {
  return (
    <div>
      <span className='d-inline-flex align-items-center'>
        <Badge className='me-1' color='info'>{item.purchaseOrderNumber}</Badge>
        {item.name}
      </span>
      <address className='m-0'>
        {contentRow(item.deliveryAddress)}
        {appendEitherOrEmpty(item.deliveryPostalCode, item.deliveryCity)}
      </address>
    </div>
  )
}

export default PurchaseOrderDetails
