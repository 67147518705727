import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen'

import loginActions from '../../actions/loginActions'
import useActions from '../../hooks/useActions'
import IconButton from '../Buttons/IconButton'

const componentActions = {
  doLogout: loginActions.logoutRequest
}

interface LogoutButtonProps {
  testId?: string
}

const LogoutButton = ({ testId = 'logout' }: LogoutButtonProps) => {
  const actions = useActions(componentActions)

  const handleLogout = () => {
    actions.doLogout(null, {})
  }

  return (
    <IconButton
      className='me-1'
      icon={faDoorOpen}
      condensed
      color='danger'
      onClick={handleLogout}
      testId={testId}
    >
      Kirjaudu ulos
    </IconButton>
  )
}

export default LogoutButton
