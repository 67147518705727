import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mfaErrorResponses, mfaResponses } from '@evelia/common/constants'

import whoAmIActions from '../../actions/whoAmIActions'
import SetupAuthenticatorAppModal from '../../components/MultiFactorAuthentication/SetupAuthenticatorAppModal'
import TotpVerificationModal from '../../components/MultiFactorAuthentication/TotpVerificationModal'
import useToggle from '../../hooks/useToggle'
import { EveliaRootState } from '../../reducerTypes'

const MFAModals = () => {
  const dispatch = useDispatch()
  const errors = useSelector((state: EveliaRootState) => state.errors)
  const { data } = useSelector((state: EveliaRootState) => state.whoAmI)
  const [isSetupAuthenticatorAppModalOpen, { enable: openSetupAuthenticatorAppModal, disable: closeSetupAuthenticatorAppModal }] = useToggle(false)
  const [isTotpVerificationModalOpen, { enable: openTotpVerificationModal, disable: closeTotpVerificationModal }] = useToggle(false)
  const updateWhoAmIFetchPayload = () => {
    dispatch(whoAmIActions.fetchRequest({ force: true }))
    closeSetupAuthenticatorAppModal()
    closeTotpVerificationModal()
  }

  useEffect(() => {
    if((errors.status === 412 && errors.errorCode === mfaErrorResponses.required.errorCode) || data.mfaStatus === mfaResponses.required.mfaStatus) {
      openTotpVerificationModal()
    } else if((errors.status === 412 && errors.errorCode === mfaErrorResponses.setupRequired.errorCode) || data?.mfaStatus === mfaResponses.setupRequired.mfaStatus) {
      openSetupAuthenticatorAppModal()
    }
  }, [errors, openTotpVerificationModal, openSetupAuthenticatorAppModal, data])
  return (
    <>
      <TotpVerificationModal
        isOpen={isTotpVerificationModalOpen}
        handleClose={data.mfaStatus === mfaResponses.required.mfaStatus ? updateWhoAmIFetchPayload : closeTotpVerificationModal}
      />
      <SetupAuthenticatorAppModal
        isOpen={isSetupAuthenticatorAppModalOpen}
        handleClose={data?.mfaStatus === mfaResponses.setupRequired.mfaStatus ? updateWhoAmIFetchPayload : closeSetupAuthenticatorAppModal}
      />
    </>
  )
}

export default MFAModals
