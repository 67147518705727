import { all } from 'redux-saga/effects'

import watchAccounts from './sagas/accountSaga'
import watchAuthors from './sagas/authorSaga'
import watchCashFlowForecastExtraValueSaga from './sagas/cashFlowForecastExtraValueSaga'
import watchCashReceipt from './sagas/cashReceiptSaga'
import watchConfigs from './sagas/configSaga'
import watchContacts from './sagas/contactSaga'
import watchCostCentres from './sagas/costCentreSaga'
import watchCostProvisions from './sagas/costProvisionSaga'
import watchCustomerGroups from './sagas/customerGroupSaga'
import watchCustomers from './sagas/customerSaga'
import watchDashboard from './sagas/dashboardSaga'
import watchDenominations from './sagas/denominationSaga'
import watchEaddresses from './sagas/eAddressSaga'
import watchEmployeeLicenceTypes from './sagas/employeeLicenceTypeSaga'
import watchEmployeeRoles from './sagas/employeeRoleSaga'
import watchEmployees from './sagas/employeeSaga'
import watchFederatedCredentials from './sagas/federatedCredentialSaga'
import watchFiles from './sagas/fileSaga'
import watchFillOutForm from './sagas/fillOutFormSaga'
import watchFormSaga from './sagas/formSaga'
import watchFormTypes from './sagas/formTypeSaga'
import watchInboundInvoices from './sagas/inboundInvoiceSaga'
import watchInboundMessages from './sagas/inboundMessageSaga'
import watchInsuranceCompanies from './sagas/insuranceCompanySaga'
import watchInsurances from './sagas/insuranceSaga'
import watchInvitations from './sagas/invitationSaga'
import watchInvoices from './sagas/invoiceSaga'
import watchLogin from './sagas/loginSaga'
import watchMachineRecords from './sagas/machineRecordSaga'
import watchMachines from './sagas/machineSaga'
import watchMiscellaneous from './sagas/miscellaneousSaga'
import occupationClassificationSaga from './sagas/occupationClassificationSaga'
import watchOfferCustomStates from './sagas/offerCustomStateSaga'
import watchOfferPostStates from './sagas/offerPostStateSaga'
import watchOffers from './sagas/offerSaga'
import watchPacketMatrices from './sagas/packetMatrixSaga'
import watchPackets from './sagas/packetSaga'
import watchPayments from './sagas/paymentSaga'
import watchPreHandoverInspections from './sagas/preHandoverInspectionSaga'
import watchProductGroups from './sagas/productGroupSaga'
import watchProductLines from './sagas/productLineSaga'
import watchProducts from './sagas/productSaga'
import watchProjects from './sagas/projectSaga'
import watchProjectTypes from './sagas/projectTypeSaga'
import watchQuickProducts from './sagas/quickProductSaga'
import watchReceipts from './sagas/receiptSaga'
import watchReceivers from './sagas/receiverSaga'
import watchReports from './sagas/reportSaga'
import salaryPeriodSaga from './sagas/salaryPeriodSaga'
import watchSuppliers from './sagas/supplierSaga'
import watchSupplyOfferCustomStates from './sagas/supplyOfferCustomStateSaga'
import watchSupplyOffer from './sagas/supplyOfferSaga'
import watchSupplyOfferTypes from './sagas/supplyOfferTypeSaga'
import watchSystemCustomers from './sagas/systemCustomerSaga'
import watchSystemMessages from './sagas/systemMessageSaga'
import watchTargets from './sagas/targetSaga'
import watchTicketRecurrenceRules from './sagas/ticketRecurrenceRuleSaga'
import watchTickets from './sagas/ticketSaga'
import watchTicketStates from './sagas/ticketStateSaga'
import watchTicketTypes from './sagas/ticketTypeSaga'
import watchTimeRecords from './sagas/timeRecordSaga'
import watchWageEarners from './sagas/wageEarnerSaga'
import watchWarehouses from './sagas/warehouseSaga'
import watchWhoAmI from './sagas/whoAmISaga'
import watchWorkCustomStates from './sagas/workCustomStateSaga'
import watchWorkForms from './sagas/workFormSaga'
import watchWorkMachines from './sagas/workMachineSaga'
import watchWorkQuickFilters from './sagas/workQuickFilterSaga'
import watchWorkRecordProducts from './sagas/workRecordProductSaga'
import watchWorkRecords from './sagas/workRecordSaga'
import watchWorkRecurrenceRules from './sagas/workRecurrenceRuleSaga'
import watchWork from './sagas/workSaga'
import watchTicketTemplateLists from './sagas/workTicketTemplateListsSaga'
import watchWorkTypes from './sagas/workTypeSaga'

export default function* rootSaga() {
  yield all([
    watchWhoAmI(),
    watchAccounts(),
    watchAuthors(),
    watchCashReceipt(),
    watchConfigs(),
    watchContacts(),
    watchCostProvisions(),
    watchCustomerGroups(),
    watchCustomers(),
    watchDashboard(),
    watchDenominations(),
    watchEmployeeLicenceTypes(),
    watchEmployeeRoles(),
    watchEmployees(),
    watchFormSaga(),
    watchFiles(),
    watchFillOutForm(),
    watchInboundInvoices(),
    watchInvitations(),
    watchInvoices(),
    watchInboundMessages(),
    watchLogin(),
    watchMachineRecords(),
    watchMachines(),
    watchMiscellaneous(),
    watchOfferCustomStates(),
    watchOffers(),
    watchPacketMatrices(),
    watchPackets(),
    watchPayments(),
    watchPreHandoverInspections(),
    watchProductGroups(),
    watchProductLines(),
    watchProducts(),
    watchProjects(),
    watchProjectTypes(),
    watchQuickProducts(),
    watchReceipts(),
    watchReceivers(),
    watchReports(),
    watchSuppliers(),
    watchSupplyOffer(),
    watchSupplyOfferCustomStates(),
    watchSupplyOfferTypes(),
    watchSystemCustomers(),
    watchSystemMessages(),
    watchTargets(),
    watchTickets(),
    watchTicketStates(),
    watchTicketTypes(),
    watchTimeRecords(),
    watchWarehouses(),
    watchWork(),
    watchWorkCustomStates(),
    watchWorkMachines(),
    watchWorkQuickFilters(),
    watchWorkRecordProducts(),
    watchWorkRecords(),
    watchWorkTypes(),
    watchOfferPostStates(),
    watchCashFlowForecastExtraValueSaga(),
    watchEaddresses(),
    watchWorkRecurrenceRules(),
    watchTicketRecurrenceRules(),
    watchFormTypes(),
    watchCostCentres(),
    watchWorkForms(),
    watchWageEarners(),
    watchInsurances(),
    watchInsuranceCompanies(),
    occupationClassificationSaga(),
    watchFederatedCredentials(),
    watchTicketTemplateLists(),
    salaryPeriodSaga()
  ])
}
