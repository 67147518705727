import { VatClassModel, VatRateModel } from '@evelia/common/types'
import { createEntityAdapter } from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

// Vat codes are managed with rtk-query helpers created on `vatCodeApi.ts`

const vatClassAdapter = createEntityAdapter<VatClassModel>({})
export const vatClassSlice = createGenericSlice({
  name: 'vatClasses',
  entityAdapter: vatClassAdapter
})
export const vatClassEntitySelectors = vatClassAdapter.getSelectors<EveliaRootState>(state => state.vatClasses)
export const vatClassActions = vatClassSlice.actions

// Vat Rates
const vatRateAdapter = createEntityAdapter<VatRateModel>({})
export const vatRateSlice = createGenericSlice({
  name: 'vatRates',
  entityAdapter: vatRateAdapter
})
export const vatRateEntitySelectors = vatRateAdapter.getSelectors<EveliaRootState>(state => state.vatRates)
export const vatRateActions = vatRateSlice.actions
