import isObject from 'lodash/isObject'

import { formatValidationErrorsList } from '../../helpers/helpers'
import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'

type NotificationMessages = {
  errorMessage?: string | null
  successMessage?: string | null
  onError?: () => void
  showValidationErrors?: boolean
}

const getDetailsMessage = (message: string, details: JSX.Element[]) => details ? <><b>{message}:</b><br />{details}</> : message

type ServerMessageError = {
  error: { data: { message?: string } }
}

type ServerMessageErrorWithValidationErrors = {
  error: ServerMessageError['error'] & { json: { validationErrors: { msg: string }[] } }
}

const hasServerErrorMessage = (error: unknown): error is ServerMessageError => {
  return isObject(error) && 'error' in error &&
        isObject(error.error) && 'data' in error.error &&
        isObject(error.error.data) && 'message' in error.error.data
}

const hasValidationErrors = (error: ServerMessageError): error is ServerMessageErrorWithValidationErrors => {
  return 'json' in error.error &&
    isObject(error.error.json) && 'validationErrors' in error.error.json
}

export const basicApiNotification = async(queryFulfilled: Promise<unknown>, { errorMessage, successMessage, onError, showValidationErrors }: NotificationMessages) => {
  try {
    await queryFulfilled
    if(successMessage != null) {
      addSuccessNotification(successMessage)
    }
  } catch(error) {
    // queryFulfilled throws a QueryFulfilledRejectionReason error which is not exported easily

    onError?.()

    if(hasServerErrorMessage(error)) {
      if(hasValidationErrors(error)) {
        addErrorNotification(showValidationErrors ? getDetailsMessage(errorMessage ?? '', formatValidationErrorsList(error.error.json.validationErrors)) : error.error.data.message)
        return
      }
      addErrorNotification(error.error.data.message)
      return
    }
    if(errorMessage) {
      addErrorNotification(errorMessage)
    }
  }
}
