import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'

import { AdministrationPermission } from '../../components/Authentication/permissionWrappers'
import { ForbiddenErrorPage } from '../../components/Errors'
import { EMAIL_SUPPORT } from '../../constants'
import { PATH_CREDITS, PATH_SETTINGSWIZARD } from '../../routes'

const creditsRoute = '/credits'
const settingsWizardRoute = '/settingswizard'

const allowedRoutes = [
  creditsRoute,
  settingsWizardRoute
]

const AuthorizeApp = ({ children }) => {
  const errors = useSelector(state => state.errors)
  const { data } = useSelector(state => state.whoAmI)
  const { isWizardRun = true } = data

  const location = useLocation()

  if(errors.status === 402 && !location.pathname.startsWith(creditsRoute)) {
    return (
      <AdministrationPermission
        fallbackComponent={ForbiddenErrorPage}
      >
        <Redirect to={PATH_CREDITS} />
      </AdministrationPermission>
    )
  } else if(!isWizardRun && !allowedRoutes.some(route => location.pathname.startsWith(route))) {
    return (
      <AdministrationPermission>
        <Redirect to={PATH_SETTINGSWIZARD} />
      </AdministrationPermission>
    )
  } else if(errors.status === 403) {
    return (
      <AdministrationPermission
        fallbackComponent={ForbiddenErrorPage}
      >
        <ForbiddenErrorPage
          message={(
            <h5>Järjestelmä on suljettu toistaiseksi<br />
              Ota yhteyttä Evelian <a href={`mailto:${EMAIL_SUPPORT}`}>asiakaspalveluun</a>
            </h5>
            )}
        />
      </AdministrationPermission>
    )
  }

  return children
}

AuthorizeApp.propTypes = {
  children: PropTypes.node
}

export default AuthorizeApp
