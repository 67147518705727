import { createApi, defaultEmbeddedNormalizer, get, upload } from '../helpers/apiHelpers'
import { parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { optionsQueryTerms, queryTermsToTemplate } from './urlTemplates'

export const normalizeWarehouses = response => {
  const data = response.record || response.records
  const {
    parents
  } = response._embedded || {}

  return {
    data,
    parents
  }
}

export const normalizeWarehouseResponseWithProducts = response => {
  return {
    data: response.records,
    products: response._embedded?.products,
    tableOptions: parseTableOptionsFromQuery(response._embedded?.options)
  }
}

export const warehouseApi = createApi({
  base: 'warehouses',
  normalizer: normalizeWarehouses,
  extraUrlTemplate: queryTermsToTemplate(['newWarehouseId', ...optionsQueryTerms])
})

export const warehouseActionsApi = createApi({
  base: 'warehouses',
  editGetParams: params => {
    if(params.productId) {
      return {
        ...params,
        base: 'products',
        id: params.productId,
        subItem: 'warehouses',
        action: 'actions'
      }
    }
    return ({ ...params, subItem: 'actions' })
  },
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: queryTermsToTemplate(optionsQueryTerms)
})

export const fetchWarehouseProductStats = ({ warehouseId, ...query }) => {
  return get(warehouseApi.baseUrlTemplate.expand({ id: warehouseId, subItem: 'stats', ...query }))
    .then(normalizeWarehouseResponseWithProducts)
}

export const generateWarehouseReport = ({ warehouseId, reportType }) =>
  get(warehouseApi.baseUrlTemplate.expand({ id: warehouseId, subItem: reportType }))

export const importWarehouseReportFromFile = ({ warehouseId, file }) => {
  const formData = new FormData()
  formData.append('inventory', file)
  return upload(warehouseApi.baseUrlTemplate.expand({ id: warehouseId, subItem: 'inventory' }), {
    body: formData
  })
}
