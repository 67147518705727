import { emptyArray } from '@evelia/common/constants'
import groupBy from 'lodash/groupBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { actionKeys } from '../constants'
import { getFilterItemsByFieldSelector, getFindItemByIdSelector, getFindItemsByIdsSelector, getTableIdsSelector } from '../helpers/selectorHelpers'
import { findCurrentEmployee } from './employeeSelectors'
import { findProductWithId } from './productSelectors'
import { getHasAccessToFeature } from './whoAmISelectors'
import { findWorkWithId } from './workSelectors'

const getWarehousesFromArgument = arg => arg.warehouses ? arg.warehouses.records : arg
const getWarehouseActionsFromArgument = arg => arg.warehouses ? arg.warehouses.warehouseActions.records : arg

export const getSubWarehousesOf = getFilterItemsByFieldSelector(getWarehousesFromArgument, 'parentWarehouseId', Number)

export const findWarehouseWithId = getFindItemByIdSelector(getWarehousesFromArgument)
export const findWarehouseWithIds = getFindItemsByIdsSelector(getWarehousesFromArgument, emptyArray)

export const findWarehouseActionsByWarehouseId = getFilterItemsByFieldSelector(getWarehouseActionsFromArgument, 'warehouseId', Number)
export const findWarehouseActionsByProductId = getFilterItemsByFieldSelector(getWarehouseActionsFromArgument, 'productId', Number)
const getWarehouseActionsByTableIds = getTableIdsSelector('warehouses.warehouseActions')
const getWarehouseProductStatsByTableIds = getTableIdsSelector('warehouses.warehouseProductStats', '_id')

export const getWarehousesWithSubWarehouses = createSelector(
  getWarehousesFromArgument,
  (_state, idField) => idField ?? 'id',
  (warehouses, idField) => {
    if(!warehouses.length) {
      return emptyArray
    }
    const decoratedWarehouses = warehouses.map(wh => ({ ...wh, subRows: [] }))
    const warehousesByLength = groupBy(decoratedWarehouses, 'warehousePath.length')
    const pathLengths = Object.keys(warehousesByLength).map(Number).sort((a, b) => b - a)
    const shortestPath = pathLengths[pathLengths.length - 1]
    pathLengths.forEach(pathLength => {
      if(pathLength === shortestPath) {
        return
      }
      const parentWarehouses = warehousesByLength[pathLength - 1]
      warehousesByLength[pathLength].forEach(subWarehouse => {
        const parentWarehouse = parentWarehouses.find(parent => parent[idField] === subWarehouse.parentWarehouseId)
        parentWarehouse?.subRows.push(subWarehouse)
      })
    })
    return warehousesByLength[shortestPath]
  }
)

export const getWarehouseStatsForProduct = createCachedSelector(
  state => state.warehouses.records,
  state => state.products.productWarehouseStats.records,
  (state, productId) => Number(productId),
  (warehouses, stats, productId) => {
    const statsWithWarehouse = stats.filter(stat => stat.productId === productId).map(stat => {
      const warehouse = findWarehouseWithId(warehouses, stat.warehouseId)
      return {
        ...warehouse,
        ...stat
      }
    })
    return getWarehousesWithSubWarehouses(statsWithWarehouse)
  })((state, productId) => `${productId}`)

export const getDecoratedProductStatsForWarehouse = createCachedSelector(
  state => state.warehouses.records,
  (state, _, tableIdentifier) => getWarehouseProductStatsByTableIds(state, tableIdentifier),
  state => state.products.records,
  (state, warehouseId) => Number(warehouseId),
  (warehouses, stats, products, warehouseId) => {
    if(!stats) {
      return emptyArray
    }

    const populatedStats = stats.filter(stat => stat.warehousePath.includes(warehouseId)).map(stat => {
      const warehouse = findWarehouseWithId(warehouses, stat.warehouseId)
      return warehouse
        ? {
            ...warehouse,
            ...stat,
            id: stat._id,
            subRows: [],
            parentRowId: stat.warehouseId === warehouseId ? null : `${stat.productId}_${warehouse.parentWarehouseId}`,
            product: findProductWithId(products, stat.productId)
          }
        : null
    }).filter(Boolean)

    populatedStats.filter(stat => stat.parentRowId).forEach(stat => {
      const parent = populatedStats.find(row => row.id === stat.parentRowId)
      parent?.subRows.push(stat)
    })

    return populatedStats.filter(stat => stat.parentRowId == null)
  }
)((state, warehouseId) => `${warehouseId}`)

export const isWarehousesEnabled = state => getHasAccessToFeature(state, actionKeys.WAREHOUSES, true)

export const getDecoratedWarehouseActionsByWarehouseId = createCachedSelector(
  (state, warehouseId, tableIdentifier) => getFilterItemsByFieldSelector(state => getWarehouseActionsByTableIds(state, tableIdentifier), 'warehouseId', Number)(state, warehouseId),
  state => state.products.records,
  (warehouseActions, products) => {
    return warehouseActions?.map(warehouseAction => ({
      ...warehouseAction,
      product: findProductWithId(products, warehouseAction.productId)
    })) ?? []
  }
)((state, warehouseId) => `${warehouseId}`)
const parseWarehouseHelpText = (work, employee, warehouses, systemCustomerSettings) => {
  let source = null
  let warehouseId = null

  if(work?.defaultWarehouseId) {
    warehouseId = work?.defaultWarehouseId
    source = 'työn'
  } else if(employee?.defaultWarehouseId) {
    warehouseId = employee.defaultWarehouseId
    source = 'työntekijän'
  } else if(systemCustomerSettings?.defaultWarehouseId) {
    warehouseId = systemCustomerSettings?.defaultWarehouseId
    source = 'yrityksen'
  }

  if(source) {
    const warehouse = warehouses?.find(warehouse => warehouse.id === warehouseId)
    return `Käytetään ${source} varastoa ${warehouse ? `"${warehouse.name}"` : ''}`
  }
  return null
}

export const getWorksWarehouseHelpText = createCachedSelector(
  findWorkWithId,
  findCurrentEmployee,
  getWarehousesFromArgument,
  state => state.systemCustomer?.settingsData?.settings,
  parseWarehouseHelpText
)((_state, workId) => `${workId}`)
