import { miniSerializeError } from '@reduxjs/toolkit'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import invitationActions from '../actions/invitationActions'
import {
  createInvitation,
  fetchInvitations,
  unsubscribe,
  updateInvitation
} from '../api/invitationApi'
import { actionTypes } from '../constants'
import { addErrorNotification, addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createSocketWatcher,
  fetchFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const watchOnInvitationSockets = createSocketWatcher('invitation', {
  created: invitationActions.createSuccess,
  updated: invitationActions.updateSuccess
})

const invitationFetchFlow = fetchFlow({
  fetchApi: fetchInvitations,
  actions: invitationActions,
  base: 'invitations',
  errorMsg: 'suositusten'
})

const invitationUpdateFlow = updateFlow(updateInvitation, invitationActions, 'Kutsu', 'Kutsun')
function* invitationCreateFlow({ record, data = {}, ...opts }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const createdInvitations = yield call(createInvitation, record, data)
    yield put(invitationActions.fetchSuccess(createdInvitations))
    if(createdInvitations.length) {
      addSuccessNotification(`${createdInvitations.length} yhteystiedolle on lähetetty kutsu onnistuneesti.`)
    } else {
      addErrorNotification('Yhtään kutsua ei lähetetty. Yhteystiedoilla on jo tunnus Eveliassa tai vastaanottaja on jo saanut kutsun.')
    }
    yield call(resolve, createdInvitations)
  } catch(err) {
    yield put(invitationActions.fetchError(miniSerializeError(err)))
    yield * genericSagaErrorHandler(err, 'Virhe kutsujen lähettämisessä', reject)
  }
}

function* invitationUnsubscribeFlow({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const { cuid } = record
    const unsubscribedInvitation = yield call(unsubscribe, cuid)
    resolve(unsubscribedInvitation)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Peruminen epäonnistui', reject)
  }
}

export default function* invitationSaga() {
  yield takeLatest(invitationActions.actionTypes.fetchRequest, invitationFetchFlow)
  yield takeEvery(invitationActions.actionTypes.updateRequest, invitationUpdateFlow)
  yield takeEvery(invitationActions.actionTypes.createRequest, invitationCreateFlow)
  yield takeEvery(actionTypes.INVITATIONS_UNSUBSCRIBE, invitationUnsubscribeFlow)

  yield all([
    watchOnInvitationSockets()
  ])
}
