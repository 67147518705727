import { capitalizeObjectValues } from '../../helpers/helpers'

const ticketTypeTitles = {
  basic: 'tyyppi',
  genetive: 'tyypin',
  partitive: 'tyyppejä',
  allative: 'tyypille',
  pluralBasic: 'tyypit',
  pluralGenetive: 'tyyppien',
  pluralPartitive: 'tyyppejä'
}

export const ticketTypeTitlesCapitalized = capitalizeObjectValues(ticketTypeTitles)

export default ticketTypeTitles
