import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import cashReceiptActions from '../actions/cashReceiptActions'
import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import productActions from '../actions/productActions'
import { showActionPrompt } from '../actions/uiActions'
import warehouseActions from '../actions/warehouseActions'
import workActions from '../actions/workActions'
import { purchaseOrderApi } from '../api/rtk/purchaseOrderApi'
import {
  fetchWarehouseProductStats,
  generateWarehouseReport,
  importWarehouseReportFromFile,
  normalizeWarehouses,
  warehouseActionsApi,
  warehouseApi
} from '../api/warehouseApi'
import { actionTypes, uiTypes } from '../constants'
import { addSuccessNotification, removeNotification } from '../helpers/notificationHelpers'
import {
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  deleteFlow,
  fetchFlow,
  generateReportFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  updateFlow
} from '../helpers/sagaHelpers'

const handleWarehouseApiResponse = mainAction =>
  function* ({ data, parents }) {
    if(parents && parents.length) {
      yield put(warehouseActions.fetchSuccess(parents))
    }
    yield put(mainAction(data))
    return data
  }

export const handleWarehouseStatsApiResponse = (mainAction, tableIdentifier) =>
  function* ({ data, products, tableOptions }) {
    yield put(productActions.fetchSuccess(products))
    yield put(mainAction(data))
    // Don't store tableOptions if tableIdentifier was not provided - this is being used from product which
    // doesn't use server pagination and neither needs one
    if(tableIdentifier && tableOptions) {
      yield put(warehouseActions.warehouseProductStats.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleWarehouseActionApiResponse = tableIdentifier => function* ({
  data,
  products,
  work,
  inboundInvoices,
  cashReceipts,
  purchaseOrders,
  tableOptions
}) {
  if(products) {
    yield put(productActions.fetchSuccess(products))
  }
  if(work) {
    yield put(workActions.fetchSuccess(work))
  }
  if(inboundInvoices) {
    yield put(inboundInvoiceActions.fetchSuccess(inboundInvoices))
  }
  if(cashReceipts) {
    yield put(cashReceiptActions.fetchSuccess(cashReceipts))
  }
  if(purchaseOrders) {
    yield put(purchaseOrderApi.util.upsertQueryEntries(purchaseOrders.map(purchaseOrder => ({ endpointName: 'getRecord', arg: purchaseOrder.id, value: { record: purchaseOrder, _embedded: {} } }))))
  }
  yield put(warehouseActions.warehouseActions.fetchSuccess(data))
  if(tableOptions) {
    yield put(warehouseActions.warehouseActions.tableActions.updateOptions(tableOptions, tableIdentifier))
  }
  return data
}

const watchOnWarehouseSockets = createSocketWatcherWithApiHandlerAndNormalizer('warehouse', warehouseActions, handleWarehouseApiResponse, normalizeWarehouses)

const warehouseFetchFlow = fetchFlow({
  fetchApi: warehouseApi.fetch,
  actions: warehouseActions,
  base: 'warehouses',
  idField: 'id',
  errorMsg: 'varastojen',
  apiResponseHandler: handleWarehouseApiResponse(warehouseActions.fetchSuccess)
})

const warehouseUpdateFlow = updateFlow(warehouseApi.update, warehouseActions, 'Varasto', 'Varaston', handleWarehouseApiResponse(warehouseActions.updateSuccess))
const warehouseCreateFlow = createFlow(warehouseApi.create, warehouseActions, 'Varasto', err => err.status === 409 ? 'Varaston nimi on jo käytössä' : 'Virhe varaston luonnissa', handleWarehouseApiResponse(warehouseActions.createSuccess))

const warehouseDeleteFlow = deleteFlow({
  deleteApi: warehouseApi.remove,
  actions: warehouseActions,
  singular: 'Varasto',
  errorMsg: 'Varaston',
  base: 'warehouses'
})

const warehouseActionFetchFlow = fetchFlow({
  fetchApi: warehouseActionsApi.fetch,
  actions: warehouseActions.warehouseActions,
  base: 'warehouses.warehouseActions',
  idField: 'id',
  errorMsg: 'varastotapahtumien',
  getApiResponseHandler: data => handleWarehouseActionApiResponse(data.tableIdentifier)
})

const warehouseProductStatsFetchFlow = fetchFlow({
  fetchApi: fetchWarehouseProductStats,
  actions: warehouseActions.warehouseProductStats,
  errorMsg: 'Varastosaldojen',
  apiResponseHandler: handleWarehouseStatsApiResponse(warehouseActions.warehouseProductStats.fetchSuccess, 'default')
})

const generateWarehouseReportFlow = generateReportFlow(generateWarehouseReport)

function* importWarehouseReportFlow({ data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const toastId = 'inventoryNotification'
  try {
    addSuccessNotification('Inventaariolistan lukeminen aloitettu', { toastId, autoClose: false })
    const response = yield call(importWarehouseReportFromFile, data)
    addSuccessNotification('Inventaariolista luettu', { toastId })
    yield call(resolve, response)
  } catch(err) {
    yield put(removeNotification(toastId))
    if(err.response.status === 400) {
      yield put(showActionPrompt(uiTypes.SHOW_WAREHOUSE_INVENTORY_ERRORS, err.json))
    }
    yield * genericSagaErrorHandler(err, err.json.message, reject)
  }
}

export default function* warehouseSaga() {
  yield takeLatest(warehouseActions.actionTypes.fetchRequest, warehouseFetchFlow)
  yield takeEvery(warehouseActions.actionTypes.updateRequest, warehouseUpdateFlow)
  yield takeEvery(warehouseActions.actionTypes.createRequest, warehouseCreateFlow)
  yield takeEvery(warehouseActions.actionTypes.deleteRequest, warehouseDeleteFlow)

  yield takeEvery(warehouseActions.warehouseActions.actionTypes.fetchRequest, warehouseActionFetchFlow)

  yield takeEvery(warehouseActions.warehouseProductStats.actionTypes.fetchRequest, warehouseProductStatsFetchFlow)

  yield takeEvery(actionTypes.WAREHOUSE_GENERATE_REPORT_REQUEST, generateWarehouseReportFlow)
  yield takeEvery(actionTypes.WAREHOUSE_REPORT_IMPORT_REQUEST, importWarehouseReportFlow)

  yield all([
    watchOnWarehouseSockets()
  ])
}
