import { emptyArray } from '@evelia/common/constants'
import { capitalize } from '@evelia/common/helpers'
import { BaseIdModel } from '@evelia/common/types'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { Draft, Patch } from 'immer'
import snakeCase from 'lodash/snakeCase'

import { getSocket } from '../../socket'
import { ApiRecordResponse, ApiResponse } from '.'
import { getBaseQuery, transformErrorResponse } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'

type ToSingularString<S extends string> = S extends `${infer P1}s` ? P1 : S

type UseRecordsOptions = {
  pollingInterval?: number
  skipPollingIfUnfocused?: boolean
  refetchOnReconnect?: boolean
  refetchOnFocus?: boolean
  skip?: boolean
  refetchOnMountOrArgChange?: number | boolean
}

type GetRecordsQueryDefinition<
  TDataModel extends BaseIdModel,
  TQueryArgs,
  TPath extends string
  > = Omit<
  QueryDefinition<TQueryArgs, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>, TPath, ApiResponse<TDataModel>, `${ToSingularString<TPath>}Api`>,
  'type'>

type UpdateRecipe<T> = (data: T) => void | T
type PatchCollection = { patches: Patch[], inversePatches: Patch[], undo: () => void }
export type UpdateCachedData<T> = (updateRecipe: UpdateRecipe<T | Draft<T>>) => PatchCollection

export const isRecordInCache = <TDataModel extends BaseIdModel >({ data }: { data?: { records: TDataModel[] } }, id: number | undefined) => {
  return id != null && (data?.records.some(record => record.id === id) ?? false)
}

export const forceRefetch = <TDataModel extends BaseIdModel>(updateCachedData: UpdateCachedData<ApiResponse<TDataModel>>) => {
  updateCachedData(draft => {
    if(draft._embedded?.options) {
      draft._embedded.options.force = true
    }
  })
}

interface CreateCrudApiProps<TDataModel extends BaseIdModel, TQueryArgs, TPath extends string> {
  path: TPath
  queryPath?: string
  queryDefinition: GetRecordsQueryDefinition<TDataModel, TQueryArgs, TPath>
  socketMatchers?: {
    created?: (record: TDataModel, queryArgs: TQueryArgs, updateCachedData: UpdateCachedData<ApiResponse<TDataModel>>) => boolean
    updated?: (record: TDataModel, queryArgs: TQueryArgs, { updateCachedData, getCacheEntry }: {
      updateCachedData: UpdateCachedData<ApiResponse<TDataModel>>
      getCacheEntry: () => { data?: { records: TDataModel[] } }
    }) => boolean
  }
  embeddedHandler?: (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: ApiResponse<TDataModel>['_embedded']) => void | Promise<void>
  invalidateOnCreate?: boolean
  invalidateOnUpdate?: boolean
  invalidateOnDelete?: boolean
  titles: {
    singular: string
    plural?: string
    genetive: string
    pluralGenetive: string
  }
  extraTagTypes?: string[]
}

export const createCRUDApi = <TDataModel extends BaseIdModel, TQueryArgs, TPath extends string>({
  path,
  queryDefinition,
  socketMatchers,
  embeddedHandler,
  invalidateOnCreate,
  invalidateOnUpdate,
  invalidateOnDelete,
  titles,
  queryPath,
  extraTagTypes = []
}: CreateCrudApiProps<TDataModel, TQueryArgs, TPath>) => {
  const singularPath = path.endsWith('s') ? path.slice(0, -1) : path
  const baseQueryPath = queryPath ?? snakeCase(path)

  const api = createApi({
    reducerPath: `${singularPath}Api`,
    baseQuery: getBaseQuery(baseQueryPath),
    tagTypes: [path, ...extraTagTypes],
    endpoints: builder => ({
      // @ts-expect-error "Type '((arg: TQueryArgs) => string | FetchArgs) | undefined' is not assignable to type '(arg: TQueryArgs) => string | FetchArgs'." - can't find where I could type it as non nullable
      getRecords: builder.query<ApiResponse<TDataModel>, TQueryArgs>({
        onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.pluralGenetive} haussa`,
          successMessage: null
        }),
        providesTags: [{ type: path }],
        transformErrorResponse,
        onCacheEntryAdded: async(queryArgs, {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getCacheEntry,
          dispatch
        }) => {
          const socket = getSocket()
          try {
            await cacheDataLoaded
            socket.on(`${singularPath}:created`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              if(!socketMatchers?.created?.(data.record, queryArgs, updateCachedData)) {
                return
              }
              updateCachedData(draft => {
                (draft.records as TDataModel[]).push(data.record)
                if(draft._embedded?.options?.ids) {
                  (draft._embedded.options.ids as number[]).push(data.record.id)
                }
                if(draft._embedded?.options?.totalCount != null) {
                  draft._embedded.options.totalCount++
                }
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:updated`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              const isMatch = socketMatchers?.updated != null ? socketMatchers.updated(data.record, queryArgs, { updateCachedData, getCacheEntry }) : isRecordInCache(getCacheEntry(), data?.record.id)
              if(!isMatch) {
                return
              }
              updateCachedData(draft => {
                const index = draft.records.findIndex(record => record.id === data.record.id)
                draft.records[index] = data.record
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:deleted`, (_channel: string, { id }: BaseIdModel) => {
              if(!isRecordInCache(getCacheEntry(), id)) {
                return
              }
              updateCachedData(draft => {
                draft.records = draft.records.filter(record => record.id !== id) as TDataModel[]
                const options = draft._embedded.options
                if(!options) {
                  return
                }
                // Force refetch
                options.force = true
                if(options.ids) {
                  options.ids = options.ids.filter(draftId => draftId !== id) as number[]
                }
                if(options.totalCount != null) {
                  options.totalCount = Math.max(0, options.totalCount - 1)
                }
              })
            })
          } catch{
          }
          await cacheEntryRemoved
          socket.off(`${singularPath}:created`)
          socket.off(`${singularPath}:updated`)
          socket.off(`${singularPath}:deleted`)
        },
        ...queryDefinition
      }),
      getRecord: builder.query<ApiRecordResponse<TDataModel>, number>({
        query: id => `/${id}`,
        providesTags: (__result, __error, id) => [{ type: path, id }],
        onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.genetive} haussa`,
          successMessage: null
        }),
        transformErrorResponse,
        onCacheEntryAdded: async(queryId, {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch
        }) => {
          const socket = getSocket()
          try {
            await cacheDataLoaded
            socket.on(`${singularPath}:updated`, (_channel: string, data: ApiRecordResponse<TDataModel>) => {
              if(data?.record.id !== queryId) {
                return
              }
              updateCachedData(draft => {
                draft.record = data.record
              })
              embeddedHandler?.(dispatch, data._embedded)
            })
            socket.on(`${singularPath}:deleted`, (_channel: string, { id }: BaseIdModel) => {
              // Returned id is PurchaseOrderRow.id so we can't filter based on equality of purchaseOrderId = queryArgs.id
              if(id !== queryId) {
                return
              }
              updateCachedData(draft => {
                // Remove the result from cache - otherwise it's possible to navigate back to deleted record page
                // + this avoids unnecessary "Record not found" error message after deletion
                draft.record = undefined as unknown as TDataModel
              })
            })
          } catch{
          }
          await cacheEntryRemoved
          socket.off(`${singularPath}:updated`)
          socket.off(`${singularPath}:deleted`)
        }
      }),
      createRecord: builder.mutation<ApiRecordResponse<TDataModel>, Omit<TDataModel, 'id'>>({
        query: body => ({
          url: '',
          method: 'POST',
          body
        }),
        transformErrorResponse,
        // TODO: create optimistic updates without fetching all records
        invalidatesTags: invalidateOnCreate ? [{ type: path }] : [],
        onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} luotu`,
          errorMessage: `Virhe ${titles.genetive} luonnissa`
        })
      }),
      updateRecord: builder.mutation<ApiRecordResponse<TDataModel>, TDataModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'PUT',
          body
        }),
        transformErrorResponse,
        invalidatesTags: invalidateOnUpdate ? [{ type: path }] : [],
        onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} päivitetty`,
          errorMessage: `Virhe ${titles.genetive} päivityksessä`
        })
      }),
      deleteRecord: builder.mutation<void, BaseIdModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'DELETE'
        }),
        transformErrorResponse,
        onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} poistettu`,
          errorMessage: `Virhe ${titles.genetive} poistossa`
        }),
        invalidatesTags: invalidateOnDelete ? [{ type: path }] : []
      })
    })
  })

  const useMutationsHook = () => {
    const [create] = api.useCreateRecordMutation()
    const [update] = api.useUpdateRecordMutation()
    const [remove] = api.useDeleteRecordMutation()
    return { create, update, remove }
  }

  const useRecord = (id: number | undefined): Omit<ReturnType<typeof api.useGetRecordQuery>, 'data'> & { data: TDataModel | undefined } => {
    return api.useGetRecordQuery(id!, {
      skip: id == null,
      selectFromResult: response => ({
        ...response,
        currentData: response?.currentData?.record,
        data: response?.data?.record
      })
    })
  }

  const useRecords = (args: TQueryArgs, options?: UseRecordsOptions): Omit<ReturnType<typeof api.useGetRecordsQuery>, 'data'> & { data: TDataModel[] } => {
    return api.useGetRecordsQuery(args, {
      ...options,
      selectFromResult: response => ({
        ...response,
        currentData: response?.currentData?.records ?? emptyArray,
        data: response?.data?.records ?? emptyArray
      })
    })
  }

  const useLazyRecords = (options?: UseRecordsOptions) => {
    return api.useLazyGetRecordsQuery({
      ...options,
      selectFromResult: response => ({
        ...response,
        currentData: response?.currentData?.records ?? emptyArray,
        data: response?.data?.records ?? emptyArray
      })
    })
  }

  return { api, useRecord, useRecords, useLazyRecords, useMutationsHook }
}
