import { parseTemplate } from 'url-template'

import { get, patch, post, put } from '../helpers/apiHelpers'

const baseUrl = '/api/invitations{/invitationId}{/action}{/cuid}'
const invitationUrlTemplate = parseTemplate(baseUrl)

export const fetchInvitations = (params = {}) => {
  return get(invitationUrlTemplate.expand(params))
}

export const updateInvitation = (invitation, params) => {
  return put(invitationUrlTemplate.expand({ invitationId: invitation.id, ...params }), {
    body: JSON.stringify(invitation)
  })
}

export const createInvitation = (invitation, params) => {
  return post(invitationUrlTemplate.expand(params), {
    body: JSON.stringify(invitation)
  })
}

export const unsubscribe = cuid => {
  return patch(invitationUrlTemplate.expand({ action: 'unsubscribe', cuid }))
}
