import { Alert } from 'reactstrap'

interface LoadingProps {
  loading: boolean
  children?: React.ReactNode
  render?: () => React.ReactNode
  testId?: string
}

const Loading = ({
  loading,
  children,
  render,
  testId
}: LoadingProps) => {
  if(loading) {
    return (
      <div data-testid={testId}>
        <Alert color='primary' fade={false}>Ladataan</Alert>
      </div>
    )
  } else if(render) {
    return render()
  } else {
    return children
  }
}

export default Loading
