import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { Container } from 'reactstrap'

import { handleVerifyOtp } from '../../api/mfaApi'
import { MFA_RELOAD_PAGE_ROUTES } from '../../containers/Main/routes'
import { addErrorNotification } from '../../helpers/notificationHelpers'
import useToggle from '../../hooks/useToggle'
import CancelButton from '../Buttons/CancelButton'
import ModalBase from '../Modals/ModalBase'
import LogoutButton from './LogoutButton'
import MultiFactorAuthenticationInfo from './MultiFactorAuthenticationInfo'
import SendSmsButton from './SendSmsButton'
import TotpInput from './TotpInput'
import TotpVerifyButton from './TotpVerifyButton'

const TotpVerificationModal = ({
  isOpen,
  handleClose,
  infoMessage,
  verifyCustomOtp,
  isCancelEnabled
}) => {
  const location = useLocation()
  const [totpToken, setTotpToken] = useState()
  const [isError, { enable: enableError, disable: disableError }] = useToggle()

  useEffect(() => {
    if(!totpToken) {
      disableError()
    }
  }, [totpToken, disableError, enableError])

  const verifyOtp = async() => {
    disableError()
    try {
      verifyCustomOtp ? await verifyCustomOtp(totpToken) : await handleVerifyOtp(totpToken)
      handleClose()
      if(MFA_RELOAD_PAGE_ROUTES.includes(location.pathname) && !verifyCustomOtp) {
        window.location.reload()
      }
    } catch(error) {
      enableError()
      addErrorNotification(error?.message || 'Tapahtui virhe')
    }
  }

  return (
    <ModalBase
      modalProps={{
        'data-testid': 'mfaVerificationModal'
      }}
      title='Lisävarmenne vaaditaan'
      isOpen={isOpen}
      onCancel={isCancelEnabled ? handleClose : undefined}
      renderBody={(
        <Container>
          <MultiFactorAuthenticationInfo message={infoMessage} />
          <TotpInput setTotpToken={setTotpToken} isError={isError} handleVerifyOtp={verifyOtp} />
          <SendSmsButton mfaSecretType='mfaSecret' />
        </Container>
      )}
      renderFooter={(
        <div className='modal-body-buttons'>
          {isCancelEnabled
            ? <CancelButton className='me-1' onClick={handleClose}>Peruuta</CancelButton>
            : <LogoutButton testId='mfaLogout' />}
          <TotpVerifyButton handleVerifyOtp={verifyOtp} disabled={!totpToken || isError} />
        </div>
      )}
    />
  )
}

TotpVerificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  infoMessage: PropTypes.string,
  verifyCustomOtp: PropTypes.func,
  isCancelEnabled: PropTypes.bool
}

export default TotpVerificationModal
